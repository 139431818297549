import React, { useEffect, useState } from "react";
import quark_topbar from "../assets/quark_topbar.png";
import "./homePage.css";
import bg_desktop from "../assets/bg_desktop.gif";
import RegisterForm from "../components/RegisterForm";
import reg_bg from "../assets/reg_bg.gif";
export default function App() {
  const [backgroundSize, setBackgroundSize] = useState(
    window.innerWidth < 768 ? "cover" : "100% 100%"
  );

  useEffect(() => {
    const handleResize = () => {
      setBackgroundSize(window.innerWidth < 768 ? "cover" : "100% 100%");
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [register, setRegister] = useState(false);

  const containerStyle = {
    height: `${
      register ? (window.innerWidth < 768 ? "125vh" : "100vh") : "100vh"
    }`,
    maxHeight: "none",
    // width: register ? "100%" : "",
    backgroundImage: `url(${register ? reg_bg : bg_desktop})`,
    backgroundSize: backgroundSize,
    backgroundPosition: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    transition: "background-image 0.5s ease-in-out",
  };

  const fontStyle = {
    color: "#AF7EA7",
    fontFamily: "Zen Dots",
    // fontSize: "32px",
    // fontStyle: "normal",
    fontWeight: 400,
    // lineHeight: "normal",
  };

  const handleEmbark = (e) => {
    e.preventDefault();
    setRegister(true);
  };

  const handleClose = (e) => {
    e.preventDefault();
    // console.log(e);
    setRegister(false);
  };

  return (
    <div style={containerStyle} className="bg-container">
      {!register && (
        <>
          <div className="  justify-center items-center top-0 absolute ">
            <img
              src={quark_topbar}
              alt="Quark Topbar "
              className="quark-logo justify-center items-center"
            />
          </div>
          <div className={`absolute top-[68vh] lg:top-[68vh]`}>
            <button
              className="rounded-3xl font-zenDots text-2xl bg-opacity-20 embark flex-shrink-0 embark-button"
              onClick={handleEmbark}
              style={{
                backgroundRepeat: "none",
                fontSize: "calc(0.88rem + 0.45vw)",
              }}
            >
              EMBARK
            </button>
          </div>
          <div
            style={fontStyle}
            className="bottom-[2vh] text-[7vm] lg:text-4xl absolute tracking-wide"
          >
            16-18TH FEBRUARY
          </div>
          <button
            className="box-border absolute lg:left-[1dvw] lg:bottom-6 left-0 bottom-[6dvh] font-zenDots text-white shadow-blackA4 hover:bg-mauve3  h-10 items-center  justify-center rounded-[20px] border-2 px-4 font-medium leading-none   mx-auto  mt-4"
            // type="submit"
            // onClick={handleFormSubmit}
            style={{ background: "rgba(255, 255, 255, 0.4)" }}
          >
            <a
              href="https://drive.google.com/file/d/1l8LX82cCgF-E9S6rKd4_-WCqKUnFhYmX/view?fbclid=PAAaZAEKMa_0tAnUqu5yRKeL5dXDSUlYrZM6J4dNnfQZfuVe7tAMmBqUtEGaw"
              target="_blank"
              rel="noopener noreferrer"
            >
              RULE BOOK
            </a>
          </button>
          <button
            className="box-border absolute lg:right-[1dvw] lg:bottom-6 right-0 bottom-[6dvh] font-zenDots text-white shadow-blackA4 hover:bg-mauve3  h-10 items-center  justify-center rounded-[20px] border-2 px-4 font-medium leading-none   mx-auto  mt-4"
            // type="submit"
            // onClick={handleFormSubmit}
            style={{ background: "rgba(255, 255, 255, 0.4)" }}
          >
            <a
              href="https://www.onlinesbi.sbi/sbicollect/"
              target="_blank"
              rel="noopener noreferrer"
            >
              PAY HERE
            </a>
          </button>
        </>
      )}

      {register && <RegisterForm handleClose={handleClose} />}
      {!register && (
        <div className="devsoc">
          <p>Made with ❤️ by DevSoc</p>
        </div>
      )}
    </div>
  );
}
