import React, { useState } from "react";
import * as Form from "@radix-ui/react-form";
import "./registerForm.css";
import Dropdown from "./Dropdown.jsx";
import indianStates from "./indianStates.js";
import close from "../assets/close.png";
import events from "./events.js";

const fontStyle1 = {
  fontFamily: "Montserrat",
  fontSize: "18px",
  fontWeight: 400,
  lineHeight: "22px",
  letterSpacing: "0em",
  textAlign: "center",
};

const fontStyle2 = {
  fontFamily: "Montserrat",
  fontSize: "24px",
  fontWeight: 400,
  lineHeight: "28px",
  letterSpacing: "0em",
  textAlign: "center",
  wordWrap: "break-word",
};

const fontStyle3 = {
  fontFamily: "Montserrat",
  fontSize: "20px",
  fontWeight: 400,
  lineHeight: "28px",
  wordWrap: "break-word",
};

const RegisterForm = (props) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [team, setTeam] = useState(false);
  const [arr, setArr] = useState([]);

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    if (option === "team") {
      setTeam(true);
      setArr([1, 2, 3, 4]);
      setData((prevData) => {
        return { ...prevData, individual: false };
      });
    }
    if (option === "individual") {
      setArr([1]);
      setData((prevData) => {
        return { ...prevData, individual: true };
      });
      setTeam(true);
    }
  };

  const [data, setData] = useState({
    name: "",
    number: "",
    college: "",
    gender: "",
    email: "",
    state: "",
    city: "",
    team: Array(arr.length).fill({ name: "", number: "" }),
    individual: true,
    poc: { name: "", number: "" },
    event: "",
  });

  const handleFormSubmit = (e) => {
    e.preventDefault();
    //console.log(data);
    //console.log(data.team[1])

    const date = new Date();
    var ISToffSet = 330; //IST is 5:30; i.e. 60*5+30 = 330 in minutes
    var ISTTime = new Date(date.getTime() + ISToffSet * 60 * 1000);
    //console.log(ISTTime)

    let usr_data = {
      Submit_Time: ISTTime,
      Name: data.name,
      Email: data.email,
      Number: data.number,
      Gender: data.gender,
      College: data.college,
      City: data.city,
      State: data.state,
      Event: data.event,
      POC_name: data.poc.name,
      POC_num: data.poc.number,
      team_0_name: data.team[0] ? data.team[0].name : "",
      team_0_num: data.team[0] ? data.team[0].number : "",
      team_1_name: data.team[1] ? data.team[1].name : "",
      team_1_num: data.team[1] ? data.team[1].number : "",
      team_2_name: data.team[2] ? data.team[2].name : "",
      team_2_num: data.team[2] ? data.team[2].number : "",
      team_3_name: data.team[3] ? data.team[3].name : "",
      team_3_num: data.team[3] ? data.team[3].number : "",
    };

    if (data.individual === true) {
      fetch(
        "https://sheet.best/api/sheets/43f66dfe-a111-45af-8448-38bbfc83aac4",
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(usr_data),
        }
      )
        .then((r) => r.json())
        .then((data) => {
          setData({
            name: "",
            number: "",
            college: "",
            gender: "",
            email: "",
            state: "",
            city: "",
            team: Array(arr.length).fill({ name: "", number: "" }),
            individual: true,
            poc: { name: "", number: "" },
            event: "",
          });
          alert("Registration Successful!");
          props.handleClose(e);
          // The response comes here
          console.log(data);
        })
        .catch((error) => {
          alert("Registration failed. Please try again.");
          // props.handleClose(e);
          // Errors are reported there
          // console.log(data);
          console.log(error);
        });
    } else {
      fetch(
        "https://sheet.best/api/sheets/462b293c-ace6-4a92-ac78-06f0a2294812",
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(usr_data),
        }
      )
        .then((r) => r.json())
        .then((data) => {
          setData({
            name: "",
            number: "",
            college: "",
            gender: "",
            email: "",
            state: "",
            city: "",
            team: Array(arr.length).fill({ name: "", number: "" }),
            individual: true,
            poc: { name: "", number: "" },
            event: "",
          });
          alert("Registration Successfull");
          // props.handleClose(e);
          // The response comes here
          console.log(data);
        })
        .catch((error) => {
          alert("Registration failed. Please try again.");
          // console.log(data);
          console.log(error);
        });
    }
  };

  const handleGender = (e) => {
    // console.log(e);
    setData((prevData) => {
      return {
        ...prevData,
        gender: e,
      };
    });
  };

  const handleState = (e) => {
    console.log(e);
    setData((prevData) => {
      return {
        ...prevData,
        state: e,
      };
    });
  };
  const handleEvent = (e) => {
    // console.log(e);
    setData((prevData) => {
      return {
        ...prevData,
        event: e,
      };
    });
  };

  const handleClose = (e) => {
    setTeam(false);
  };
  // useEffect(() => {
  //   console.log(data);
  // }, [data]);

  return (
    <>
      <div className="flex items-center justify-center flex-col  lg:h-screen">
        <div className="font-zenDots font-[500] text-xs absolute md:text-2xl pb-[2rem] pt-[1rem] flex flex-nowrap lg:relative top-0 text-[#fff] venture">
          VENTURE INTO THE SYNAPTIC SYNTHSCAPE...
        </div>
        <Form.Root
          className=" md:w-[50vw] lg:w-[100%] h-[40vh] md:h-[40vw] w-[100%] lg:h-[40vw] min-h-[30vh] max-w-[90vw] flex-1 flex flex-col lg:flex-row lg:mt-2 "
          onSubmit={handleFormSubmit}
        >
          <div
            style={{
              flex: "1",
              border: "2px solid #fff",
              borderRadius: "30px",
              backdropFilter: "blur(10px)",
              background: "rgba(255, 255, 255, 0.35)",
            }}
            className={`lg:p-[4rem] lg:pt-[5rem] lg:pb-[3rem] p-4  ${
              team ? "pt-[1rem]" : "pt-[4rem]"
            }
            } flex-col items-center w-full`}
          >
            {!team && (
              <div
                style={{
                  position: "absolute",
                  top: "1rem",
                  right: "1rem",
                  cursor: "pointer",
                  height: "30px",
                  width: "30px",
                  borderRadius: "0px 9px 0px 9px",
                  backgroundColor: "rgba(132, 48, 98, 0.42)",
                }}
                onClick={props.handleClose}
              >
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    border: "1px rgba(0, 0, 0, 0) solid",
                  }}
                  src={close}
                  alt="close button"
                />
              </div>
            )}
            <Form.Field name={`name`}>
              <Form.Control asChild>
                <input
                  style={{
                    ...fontStyle3,
                  }}
                  required
                  className="box-border text-black w-full bg-transparent shadow-blackA6 inline-flex h-10 appearance-none items-center justify-center rounded-[4px] px-4 text-[15px] leading-none shadow-[0_0_0_1px] outline-none hover:shadow-[0_0_0_1px_black] focus:shadow-[0_0_0_2px_black] selection:color-white selection:bg-blackA6"
                  type="text"
                  placeholder={`Full Name`}
                  onChange={(e) =>
                    setData((prevData) => {
                      return { ...prevData, name: e.target.value };
                    })
                  }
                />
              </Form.Control>
            </Form.Field>
            <Form.Field name={`email`} className="lg:mt-6 mt-[0.75rem]">
              <Form.Control asChild>
                <input
                  style={{ ...fontStyle3, "::placeholder": { color: "black" } }}
                  className="box-border w-full bg-transparent shadow-blackA6 inline-flex h-10 appearance-none items-center justify-center rounded-[4px] px-4 text-[15px] leading-none shadow-[0_0_0_1px] outline-none hover:shadow-[0_0_0_1px_black] focus:shadow-[0_0_0_2px_black] selection:color-white selection:bg-blackA6"
                  type="email"
                  placeholder={`Email`}
                  onChange={(e) =>
                    setData((prevData) => {
                      return { ...prevData, email: e.target.value };
                    })
                  }
                />
              </Form.Control>
            </Form.Field>
            <Form.Field name={`phone`} className="lg:mt-6 mt-[0.75rem]">
              <Form.Control asChild>
                <input
                  style={fontStyle3}
                  className="box-border w-full bg-transparent shadow-blackA6 inline-flex h-10 appearance-none items-center justify-center rounded-[4px] px-4 text-[15px] leading-none shadow-[0_0_0_1px] outline-none hover:shadow-[0_0_0_1px_black] focus:shadow-[0_0_0_2px_black] selection:color-white selection:bg-blackA6"
                  type="number"
                  pattern="\d*"
                  placeholder={`Phone Number`}
                  onChange={(e) =>
                    setData((prevData) => {
                      return { ...prevData, number: e.target.value };
                    })
                  }
                />
              </Form.Control>
            </Form.Field>
            <Form.Field name={`gender`} className="lg:mt-6 mt-[0.75rem]">
              <div className="flex justify-between">
                <Dropdown
                  options={["Male", "Female", "Others"]}
                  name="Gender"
                  handleOptionChange={handleGender}
                />
                {!team && (
                  <span className="items-center">
                    <button
                      className={`box-border font-zenDots text-white max-w-[25vw] text-[0.55rem] lg:text-[100%] inline-flex h-11 items-center justify-center rounded-l-[2rem] bg-transparent px-4 font-medium leading-none shadow-[0_2px_10px] focus:shadow-[0_0_0_2px] focus:shadow-black focus:outline-none  `}
                      style={{
                        background:
                          "linear-gradient(rgba(143, 0, 255, 1),rgba(140, 0, 249, 1),  rgba(170, 0, 240, 0.85), rgba(216, 70, 240, 0.75) )",
                      }}
                      onClick={() => handleOptionChange("individual")}
                    >
                      INDIVIDUAL
                    </button>
                    <button
                      className={`box-border font-zenDots text-white  max-w-[19vw] text-[0.55rem] lg:text-[100%] inline-flex h-11 items-center justify-center rounded-r-[2rem] bg-transparent px-4 font-medium leading-none shadow-[0_2px_10px] focus:shadow-[0_0_0_2px]  focus:outline-none  `}
                      style={{
                        background:
                          "linear-gradient(rgba(143, 0, 255, 1),rgba(140, 0, 249, 1),  rgba(170, 0, 240, 0.85), rgba(216, 70, 240, 0.75) )",
                      }}
                      onClick={() => handleOptionChange("team")}
                    >
                      TEAM
                    </button>
                  </span>
                )}
              </div>
            </Form.Field>
            <Form.Field name={`college`} className="lg:mt-6 mt-[0.75rem]">
              <Form.Control asChild>
                <input
                  style={fontStyle3}
                  className="box-border w-full bg-transparent shadow-blackA6 inline-flex h-10 appearance-none items-center justify-center rounded-[4px] px-4 text-[15px] leading-none shadow-[0_0_0_1px] outline-none hover:shadow-[0_0_0_1px_black] focus:shadow-[0_0_0_2px_black] selection:color-white selection:bg-blackA6"
                  type="text"
                  placeholder={`College Name`}
                  onChange={(e) =>
                    setData((prevData) => {
                      return { ...prevData, college: e.target.value };
                    })
                  }
                />
              </Form.Control>
            </Form.Field>
            <Form.Field name="state" className="lg:mt-6 mt-[0.75rem]">
              <span className="flex items-center">
                <Dropdown
                  options={indianStates}
                  name="State"
                  handleOptionChange={handleState}
                />
                <input
                  style={fontStyle3}
                  className="box-border ml-[2vw] w-full bg-transparent shadow-blackA6 inline-flex h-10 appearance-none items-center justify-center rounded-[4px] px-4 text-[15px] leading-none shadow-[0_0_0_1px] outline-none hover:shadow-[0_0_0_1px_black] focus:shadow-[0_0_0_2px_black] selection:color-white selection:bg-blackA6"
                  type="text"
                  placeholder={`City`}
                  onChange={(e) =>
                    setData((prevData) => {
                      return { ...prevData, city: e.target.value };
                    })
                  }
                />
              </span>
            </Form.Field>
          </div>
          {team && (
            <>
              <div
                style={{
                  border: "2px solid #fff",
                  borderRadius: "30px",
                  backdropFilter: "blur(10px)",
                  // padding: "4rem",
                  // paddingTop: "1rem",
                  // paddingBottom: "1rem",
                  background: "rgba(255, 255, 255, 0.8)",
                }}
                className="flex flex-col lg:p-16 lg:pt-4 lg:pb-4 px-4"
              >
                <div
                  style={{
                    position: "absolute",
                    top: "1rem",
                    right: "1rem",
                    cursor: "pointer",
                    height: "30px",
                    width: "30px",
                    borderRadius: "0px 9px 0px 9px",
                    backgroundColor: "rgba(132, 48, 98, 0.42)",
                    //   backgroundImage: { close_bg },
                  }}
                  onClick={handleClose}
                >
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      border: "1px rgba(0, 0, 0, 0) solid",
                    }}
                    src={close}
                    alt="close button"
                  />
                </div>
                <div
                  className={`box-border  font-zenDots text-white w-[10rem] mx-auto inline-flex h-10 items-center justify-center rounded-[2rem] bg-transparent px-4 font-medium leading-none  }`}
                  style={{
                    background:
                      "linear-gradient(rgba(143, 0, 255, 1),rgba(140, 0, 249, 1),  rgba(170, 0, 240, 0.85), rgba(216, 70, 240, 0.75) )",
                  }}
                >
                  {selectedOption.toUpperCase()}
                </div>
                <div className="justify-center items-center flex  mt-2 ">
                  <Dropdown
                    options={events}
                    name="EVENTS"
                    handleOptionChange={handleEvent}
                  />
                </div>
                <div className=" border rounded-[0.5rem] mt-3 border-black p-4">
                  {arr.map((pair, index) => (
                    <div key={pair} className="grid mb-2 grid-cols-3 gap-4">
                      <div className="mr-1" style={fontStyle2}>
                        {index + 1}
                      </div>
                      <Form.Field name={`name_${pair}`}>
                        <Form.Control asChild>
                          <input
                            style={fontStyle1}
                            className="box-border w-full bg-transparent shadow-blackA6 inline-flex h-10 appearance-none items-center justify-center rounded-[4px] px-4 text-[15px] leading-none shadow-[0_0_0_1px] outline-none hover:shadow-[0_0_0_1px_black] focus:shadow-[0_0_0_2px_black] selection:color-white selection:bg-blackA6"
                            type="text"
                            placeholder={`Full Name`}
                            onChange={(e) =>
                              setData((prevData) => {
                                let team = [...prevData.team];
                                team[index] = {
                                  ...prevData.team[index],
                                  name: e.target.value,
                                };
                                const newData = { ...prevData, team };
                                return newData;
                              })
                            }
                          />
                        </Form.Control>
                      </Form.Field>
                      <Form.Field name={`number_${pair}`}>
                        <Form.Control asChild>
                          <input
                            style={fontStyle1}
                            className="box-border w-full bg-transparent shadow-blackA6 inline-flex h-10 appearance-none items-center justify-center rounded-[4px] px-4 text-[15px] leading-none text-black shadow-[0_0_0_1px] outline-none hover:shadow-[0_0_0_1px_black] focus:shadow-[0_0_0_2px_black] selection:color-white selection:bg-blackA6"
                            type="number"
                            placeholder={`Phone Number`}
                            onChange={(e) =>
                              setData((prevData) => {
                                let team = [...prevData.team];
                                team[index] = {
                                  ...prevData.team[index],
                                  number: e.target.value,
                                };
                                const newData = { ...prevData, team };
                                return newData;
                              })
                            }
                          />
                        </Form.Control>
                      </Form.Field>
                    </div>
                  ))}
                </div>
                <div className=" lg:mt-8 border rounded-[0.5rem] border-black lg:p-4 p-2 mt-2 mb-2">
                  <div className="font-montSerrat font-[500]  lg:mb-4 text-xl mb-2">
                    Point of Contact (POC)
                  </div>
                  <div className="inline-flex gap-5">
                    <div></div>
                    <Form.Field name={`name_poc`}>
                      <Form.Control asChild>
                        <input
                          style={fontStyle1}
                          className="box-border w-full  bg-transparent shadow-blackA6 inline-flex h-10 appearance-none items-center justify-center rounded-[4px] px-4 text-[15px] leading-none shadow-[0_0_0_1px] outline-none hover:shadow-[0_0_0_1px_black] focus:shadow-[0_0_0_2px_black] selection:color-white selection:bg-blackA6"
                          type="text"
                          placeholder={`Full Name`}
                          onChange={(e) =>
                            setData((prevData) => ({
                              ...prevData,
                              poc: { ...prevData.poc, name: e.target.value },
                            }))
                          }
                        />
                      </Form.Control>
                    </Form.Field>
                    <Form.Field name={`number_poc`}>
                      <Form.Control asChild>
                        <input
                          style={fontStyle1}
                          className="box-border w-full bg-transparent shadow-blackA6 inline-flex h-10 appearance-none items-center justify-center rounded-[4px] px-4 text-[15px] leading-none text-black shadow-[0_0_0_1px] outline-none hover:shadow-[0_0_0_1px_black] focus:shadow-[0_0_0_2px_black] selection:color-white selection:bg-blackA6"
                          type="number"
                          placeholder={`Phone Number`}
                          onChange={(e) =>
                            setData((prevData) => ({
                              ...prevData,
                              poc: { ...prevData.poc, number: e.target.value },
                            }))
                          }
                        />
                      </Form.Control>
                    </Form.Field>
                  </div>
                </div>
              </div>
            </>
          )}
        </Form.Root>
        <div className="pt-1 mb-2 flex gap-2">
          {/* <Form.Submit asChild className=""> */}
          <button
            className="box-border   font-zenDots text-white shadow-blackA4 hover:bg-mauve3  h-10 items-center  justify-center rounded-[20px] border-2 px-4 font-medium leading-none   mx-auto  mt-4"
            type="submit"
            onClick={handleFormSubmit}
            style={{ background: "rgba(255, 255, 255, 0.4)" }}
          >
            REGISTER
          </button>
          <button
            className="box-border   font-zenDots text-white shadow-blackA4 hover:bg-mauve3  h-10 items-center  justify-center rounded-[20px] border-2 px-4 font-medium leading-none   mx-auto  mt-4"
            style={{ background: "rgba(255, 255, 255, 0.4)" }}
          >
            <a
              href="https://drive.google.com/file/d/1l8LX82cCgF-E9S6rKd4_-WCqKUnFhYmX/view?fbclid=PAAaZAEKMa_0tAnUqu5yRKeL5dXDSUlYrZM6J4dNnfQZfuVe7tAMmBqUtEGaw"
              target="_blank"
              rel="noopener noreferrer"
            >
              RULE BOOK
            </a>
          </button>
          {/* </Form.Submit> */}
        </div>
      </div>
    </>
  );
};

export default RegisterForm;
