const events = [
  "Line Follower Bot",
  "Robowars",
  "Robosumo",
  "RoboClench",
  "RoboRace",
  "RoboSoccer",
  "Regalia",
  "Case Crackdown",
  "Bullion Beatdown",
  "Marketing Mayhem",
  "Fix the Product - A PM's Dillemma",
  "Valorant",
  "FIFA",
  "BGMI",
  "Hackathon",
  "Codejam",
  "BITSCTF",
  "Fools Coding",
  "Hack me if you can",
  "Burnout",
  "Trailblazers",
  "Search and Rescue",
  "Quark National Quiz",
  "Torque and Trivia",
  "Gaanimatoonics",
  "Digilogica",
  "uC mania",
  "Prompt Engineering",
  "AnFTee",
  "Cryptomania",
  "Enigmatica",
  "Open Showcase",
  "Quiz",
  "Debate",
];

export default events;
